import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus } from "@fortawesome/free-solid-svg-icons";
import { Box, IconButton, TextField, Typography } from "@mui/material";

const QuestionsFields = ({
  question,
  index,
  onDelete,
  disableDelete,
  error,
  onChange,
}) => {
  return (
    <Box
      key={index}
      sx={{
        padding: "9px",
        marginBottom: 2,
        marginTop: "15px",
        marginRight: "20px",
        borderRadius: "6px",
        position: "relative",
        border: "1px solid #ccc",
      }}
    >
      <IconButton
        sx={{
          top: "-20px",
          width: "45px",
          right: "-20px",
          color: "#D2122E",
          position: "absolute",
        }}
        onClick={onDelete}
        disabled={disableDelete}
      >
        <FontAwesomeIcon icon={faCircleMinus} />
      </IconButton>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            marginRight: "10px",
            flexDirection: "column",
          }}
        >
          <Typography variant="body2" fontWeight="bold">
            {`Question ${index + 1}`}
            {index === 0 && <span style={{ color: "red" }}>*</span>}
          </Typography>
          <TextField
            fullWidth
            value={question}
            onChange={onChange}
            variant="outlined"
            placeholder={`Question`}
            error={!!error}
            helperText={error}
            sx={{
              marginTop: "4px",
              marginBottom: "10px",
              backgroundColor: "white",
              "& .MuiInputBase-root": { height: "36px" },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: error ? "#d32f2f" : "black",
                },
              },
            }}
            InputProps={{
              sx: { fontSize: "14px" },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default QuestionsFields;
