import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  Snackbar,
  Select,
  MenuItem,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus, faCirclePlus } from "@fortawesome/free-solid-svg-icons";

const VariablesTab = ({
  variables,
  options,
  handleVariableChange,
  handleAddVariable,
  handleDeleteVariable,
  onSaveVariables,
  mappedFields,
}) => {
  const [errors, setErrors] = useState({});
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    isError: false,
  });

  console.log("Available CSV options:", options); // Debug log

  const validateAndSave = () => {
    const newErrors = {};
    let hasErrors = false;

    variables.forEach((variable, index) => {
      if (!variable.name.trim()) {
        newErrors[`name-${index}`] = true;
        hasErrors = true;
      }
      if (!variable.value.trim()) {
        newErrors[`value-${index}`] = true;
        hasErrors = true;
      }
    });

    setErrors(newErrors);

    if (hasErrors) {
      setSnackbar({
        open: true,
        message: "All fields are required",
        isError: true,
      });
      return;
    }

    const processedVariables = variables
      .filter(
        (variable) =>
          variable.name.trim() !== "" && variable.value.trim() !== ""
      )
      .map((variable) => {
        const systemKey =
          Object.entries(mappedFields || {}).find(
            ([_, value]) => value === variable.value
          )?.[0] || variable.value;

        return {
          ...variable,
          name: variable.name.replace(/\s+/g, ""),
          value: systemKey,
        };
      });

    console.log("Saving variables with system keys:", processedVariables);
    onSaveVariables(processedVariables);

    setSnackbar({
      open: true,
      message: "Variables saved successfully",
      isError: false,
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleNameChange = (index, value) => {
    const sanitizedValue = value.replace(/\s+/g, "");
    handleVariableChange(index, "name", sanitizedValue);
  };

  return (
    <Box sx={{ p: 2 }}>
      {variables.map((variable, index) => (
        <Box key={index} sx={{ display: "flex", gap: 2, mb: 2 }}>
          <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
            <Typography variant="subtitle2" fontWeight="bold">
              Name
            </Typography>
            <TextField
              value={variable.name}
              onChange={(e) => handleNameChange(index, e.target.value)}
              error={errors[`name-${index}`]}
              sx={{
                width: "100%",
                "& .MuiInputBase-root": { height: "36px" },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "black" },
                  "&:hover fieldset": { borderColor: "black" },
                  "&.Mui-focused fieldset": { borderColor: "black" },
                },
              }}
            />
          </Box>
          <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
            <Typography variant="subtitle2" fontWeight="bold">
              Value
            </Typography>
            <Select
              value={variable.value}
              onChange={(e) =>
                handleVariableChange(index, "value", e.target.value)
              }
              error={errors[`value-${index}`]}
              sx={{
                width: "100%",
                height: "36px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "black" },
                  "&:hover fieldset": { borderColor: "black" },
                  "&.Mui-focused fieldset": { borderColor: "black" },
                },
              }}
              displayEmpty
            >
              <MenuItem value="">
                <em>Select fields</em>
              </MenuItem>
              {Array.isArray(options) && options.length > 0 ? (
                options.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No CSV fields available</MenuItem>
              )}
            </Select>
          </Box>
          <Box
            sx={{
              width: 90,
              marginTop: "20px",
              display: "flex",
              gap: 1,
              flexShrink: 0,
            }}
          >
            {variables.length > 1 && (
              <IconButton
                onClick={() => handleDeleteVariable(index)}
                sx={{ color: "#d32f2f" }}
              >
                <FontAwesomeIcon icon={faCircleMinus} />
              </IconButton>
            )}
            {index === variables.length - 1 && (
              <IconButton onClick={handleAddVariable}>
                <FontAwesomeIcon icon={faCirclePlus} />
              </IconButton>
            )}
          </Box>
        </Box>
      ))}
      <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Button
          variant="contained"
          onClick={validateAndSave}
          sx={{
            mt: 2,
            backgroundColor: "#015d86",
            "&:hover": { backgroundColor: "#014a6d" },
          }}
        >
          <Typography sx={{ fontSize: "13px" }}>Save Variables</Typography>
        </Button>
      </Box>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: snackbar.isError ? "#d32f2f" : "#43a047",
          },
        }}
      />
    </Box>
  );
};

export default VariablesTab;
