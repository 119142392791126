import React, { useState } from "react";
import { useDrag } from "react-dnd";
import { DndProvider } from "react-dnd";
import { GridLoader } from "react-spinners";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiagramProject, faGear } from "@fortawesome/free-solid-svg-icons";
import { Box, Button, Tabs, Tab, Typography, Chip, Stack, TextField } from "@mui/material";
import GeneralTab from "./GeneralTab";
import VariablesTab from "./VariablesTab";
import EmailBuilder from "../../../components/EmailBuilder/EmailBuilder";

const DraggableChip = ({ variable, onDrop }) => {
  const [{ isDragging }, drag] = useDrag({
    type: "VARIABLE",
    item: { value: variable.name },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <Chip
        label={variable.value}
        onClick={() => onDrop(variable.value)}
        sx={{
          backgroundColor: "#e3f2fd",
          "&:hover": {
            backgroundColor: "#90caf9",
          },
          cursor: "pointer",
        }}
      />
    </div>
  );
};

const EmailCampaignForm = ({
  senderName,
  onSenderNameChange,
  domain,
  onDomainChange,
  senderEmail,
  onSenderEmailChange,
  emailSubject,
  onEmailSubjectChange,
  isEditorLoading,
  isSending,
  selectedContacts,
  onEditorLoad,
  emailEditorRef,
  onSendCampaign,
  sampleDomainOptions,
  slideOverStyles,
  onSaveVariables,
  variableOptions,
  mappedFields,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [focusedField, setFocusedField] = useState(null);
  const [variables, setVariables] = useState([{ name: "", value: "" }]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleAddVariable = () => {
    setVariables([...variables, { name: "", value: "" }]);
  };

  const handleVariableChange = (index, field, value) => {
    const newVariables = variables.map((variable, i) => {
      if (i === index) {
        return { ...variable, [field]: value };
      }
      return variable;
    });
    setVariables(newVariables);
  };

  const handleDeleteVariable = (index) => {
    const newVariables = variables.filter((_, i) => i !== index);
    if (newVariables.length === 0) {
      setVariables([{ name: "", value: "" }]);
    } else {
      setVariables(newVariables);
    }
  };

  const handleChipClick = (variableValue) => {
    if (!focusedField) return;

    const value = `{{${variableValue}}}`;

    if (focusedField === "subject") {
      const cursorPosition =
        document.getElementById("email-subject").selectionStart;
      const currentValue = emailSubject;
      const newValue =
        currentValue.slice(0, cursorPosition) +
        value +
        currentValue.slice(cursorPosition);
      onEmailSubjectChange({ target: { value: newValue } });
    } else if (focusedField === "senderName") {
      const cursorPosition =
        document.getElementById("sender-name").selectionStart;
      const currentValue = senderName;
      const newValue =
        currentValue.slice(0, cursorPosition) +
        value +
        currentValue.slice(cursorPosition);
      onSenderNameChange({ target: { value: newValue } });
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{ height: "100%", minHeight: "100%" }}
        >
          <Tab
            label="General"
            iconPosition="start"
            icon={
              <FontAwesomeIcon
                icon={faGear}
                style={{
                  fontSize: "14px",
                  marginBottom: "3px",
                  marginRight: "10px",
                }}
              />
            }
            sx={{
              minHeight: "40px",
              height: "40px",
            }}
          />
          <Tab
            label="Variables"
            iconPosition="start"
            icon={
              <FontAwesomeIcon
                icon={faDiagramProject}
                style={{
                  fontSize: "14px",
                  marginBottom: "3px",
                  marginRight: "10px",
                }}
              />
            }
            sx={{
              minHeight: "40px",
              height: "40px",
            }}
          />
        </Tabs>
      </Box>
      {activeTab === 0 ? (
        <GeneralTab
          senderName={senderName}
          onSenderNameChange={onSenderNameChange}
          domain={domain}
          onDomainChange={onDomainChange}
          senderEmail={senderEmail}
          onSenderEmailChange={onSenderEmailChange}
          emailSubject={emailSubject}
          onEmailSubjectChange={onEmailSubjectChange}
          isSending={isSending}
          sampleDomainOptions={sampleDomainOptions}
          onSenderNameFocus={() => setFocusedField("senderName")}
          onSenderNameBlur={() => setFocusedField(null)}
          onSubjectFocus={() => setFocusedField("subject")}
          onSubjectBlur={() => setFocusedField(null)}
        />
      ) : (
        <VariablesTab
          variables={variables}
          options={variableOptions}
          handleVariableChange={handleVariableChange}
          handleAddVariable={handleAddVariable}
          handleDeleteVariable={handleDeleteVariable}
          onSaveVariables={onSaveVariables}
          mappedFields={mappedFields}
        />
      )}
      <Box sx={{ p: 2, borderBottom: "1px solid rgba(0, 0, 0, 0.12)", mt: -2 }}>
        <Typography variant="subtitle2" fontWeight="bold" sx={{ mb: 1 }}>
          Available Variables
        </Typography>
        {variables[0].name !== "" ? (
          <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
            {variables.map((variable, index) => (
              <DraggableChip
                key={index}
                variable={variable}
                onDrop={handleChipClick}
              />
            ))}
          </Stack>
        ) : (
          <Typography variant="body2" color="text.secondary">
            No variables have been created yet. Create them in the Variables
            tab.
          </Typography>
        )}
      </Box>
      {isEditorLoading && (
        <Box sx={slideOverStyles.loaderContainer}>
          <GridLoader />
        </Box>
      )}
      <EmailBuilder
        variables={variables}
        selectedContacts={selectedContacts}
        onEditorLoad={onEditorLoad}
        exportHtml={emailEditorRef}
      />
      <Button
        variant="contained"
        onClick={onSendCampaign}
        disabled={isSending || !emailSubject.trim()}
        sx={{
          position: "absolute",
          bottom: 16,
          right: 16,
          backgroundColor: "#015d86",
          "&:hover": { backgroundColor: "#014a6d" },
        }}
      >
        <Typography sx={{ fontSize: "13px" }}>
          {isSending ? "Sending..." : "Send Campaign"}
        </Typography>
      </Button>
    </DndProvider>
  );
};

export default EmailCampaignForm;
