import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  IconButton,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  Alert,
  Snackbar,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { Close, ArrowBack, Warning } from "@mui/icons-material";
import SMSCampaignForm from "./SMSCampaignForm";
import EmailCampaignForm from "./EmailCampaignForm";
import ContactsSelectionTable from "./ContactsSelectionTable";
import ContactSelectionButtons from "./ContactSelectionButton";
import { slideOverStyles } from "./styles/CampaignSlideOver.styles";
import campaignService from "../../../services/campaign/campaignService";
import ContactImport from "../../../components/ContactImport/ContactImport";
import { API } from "aws-amplify";
import { getCustomer } from "../../../graphql/queries";

const steps = ["Select Contacts", "Create Campaign"];

const CampaignSlideOver = ({ open, type, agentId, onClose, userId, customerFromClaim }) => {
  const emailEditorRef = useRef();
  const [error, setError] = useState(null);
  const [domain, setDomain] = useState("");
  const [variables, setVariables] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [senderName, setSenderName] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [senderEmail, setSenderEmail] = useState("");
  const [campaignId, setCampaignId] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [currentFile, setCurrentFile] = useState(null);
  const [emailSubject, setEmailSubject] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [mappedFields, setMappedFields] = useState(null);
  const [selectionMode, setSelectionMode] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [isEditorLoading, setIsEditorLoading] = useState(true);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [userDomains, setUserDomains] = useState([]);
  const [userPhoneNumbers, setUserPhoneNumbers] = useState([]);

  const isEmail = type === "email";

  useEffect(() => {
    if (!open) {
      resetState();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      setCampaignId(uuidv4());
    }
  }, [open]);

  useEffect(() => {
    if (open && agentId) {
      fetchUserDomains().catch(error => {
        setError(error.message);
        console.error("Failed to fetch domains:", error);
      });
    }
  }, [open, agentId]);

  const resetState = () => {
    setSelectedContacts([]);
    setActiveStep(0);
    setIsEditorLoading(true);
    setError(null);
    setSuccessMessage("");
    setEmailSubject("");
  };

  const updateVariables = () => {
    if (variables?.length && emailEditorRef?.current?.editor) {
      const variablesToSet = {};
      for (const variable of variables) {
        variablesToSet[variable.value] = {
          name: variable.name,
          value: `{{${variable.value}}}`,
          sample: `{{${variable.value}}}`,
        };
      }

      emailEditorRef?.current?.editor?.setMergeTags(variablesToSet);
    }
  };

  const handleBack = () => {
    setActiveStep(0);
  };

  const handleEditorLoad = () => {
    updateVariables();
    setIsEditorLoading(false);
  };

  const handleContactsImport = (contacts, file, mappedFields) => {
    if (!file.name.toLowerCase().endsWith(".csv")) {
      setError("Only CSV files are allowed");
      return;
    }

    console.log("📁 CSV file validation:", {
      fileName: file.name,
      fileType: file.type,
      isCSV:
        file.type === "text/csv" || file.name.toLowerCase().endsWith(".csv"),
    });

    const headers = Object.values(mappedFields).filter(Boolean);
    console.log("CSV Headers for variables:", headers);

    setCsvHeaders(headers);
    setSelectedContacts((prev) => [...prev, ...contacts]);
    setCurrentFile(file);
    setMappedFields(mappedFields);
    setActiveStep(1);
  };

  const updateEmailAddress = (name, domain) => {
    const email = name && domain ? `${name}@${domain}` : "";
    setSenderEmail(email);
  };

  const handleSenderNameChange = (event) => {
    const name = event.target.value.replace(/[^a-zA-Z ]/g, '');
    setSenderName(name);
    updateEmailAddress(name, domain);
  };

  const handleDomainChange = (event) => {
    const selectedDomain = event.target.value;
    setDomain(selectedDomain);
    updateEmailAddress(senderName, selectedDomain);
  };

  const handleSenderEmailChange = (event) => {
    setSenderEmail(event.target.value);
  };

  const handleSendCampaign = async () => {
    try {
      console.log("🎯 Campaign send initiated:", {
        selectionMode,
        hasContactFile: !!currentFile,
        selectedContactsCount: selectedContacts?.length,
        timestamp: new Date().toISOString(),
      });
      setError(null);
      setIsSending(true);

      if (!emailSubject.trim()) {
        throw new Error("Please enter an email subject");
      }

      // Convert display names to values in subject and sender name
      let processedSubject = emailSubject;
      let processedSenderName = senderName;

      // Create a map of display names to values
      const variableMap = variables.reduce((acc, v) => {
        acc[v.name] = v.value;
        return acc;
      }, {});

      // Replace all variables in subject and sender name
      const variableRegex = /\{\{([^}]+)\}\}/g;

      processedSubject = processedSubject.replace(
        variableRegex,
        (match, displayName) => {
          const value = variableMap[displayName];
          return value ? `{{${value}}}` : match;
        }
      );

      processedSenderName = processedSenderName.replace(
        variableRegex,
        (match, displayName) => {
          const value = variableMap[displayName];
          return value ? `{{${value}}}` : match;
        }
      );

      // Validate that all variables exist
      const subjectVariables = [
        ...processedSubject.matchAll(variableRegex),
      ].map((match) => match[1]);
      const senderNameVariables = [
        ...processedSenderName.matchAll(variableRegex),
      ].map((match) => match[1]);

      const definedVariableValues = variables.map((v) => v.value);
      const undefinedVariables = [
        ...subjectVariables,
        ...senderNameVariables,
      ].filter((v) => !definedVariableValues.includes(v));

      if (undefinedVariables.length > 0) {
        throw new Error(
          `Undefined variables used: ${undefinedVariables.join(", ")}`
        );
      }

      // Get email content
      let emailHtml;
      try {
        emailHtml = await emailEditorRef.current();

        // Replace all variables in the HTML content
        emailHtml = emailHtml.replace(variableRegex, (match, displayName) => {
          const value = variableMap[displayName];
          return value ? `{{${value}}}` : match;
        });
      } catch (error) {
        console.error("Failed to get email-unlayer content:", error);
        throw new Error(
          "Failed to get email content. Please ensure the editor is loaded properly."
        );
      }

      const result = await campaignService.createAndSendCampaign({
        agentId,
        campaignId,
        emailSubject: processedSubject.trim(),
        emailHtml,
        selectedContacts,
        senderEmail: process.env.REACT_APP_SES_SENDER_EMAIL || senderEmail,
        senderName: senderName,
        contactsFile: currentFile,
        mappedFields: mappedFields,
        selectionMode: selectionMode,
      });

      console.log("✅ Campaign send successful:", {
        recipientCount: result.recipientCount,
        campaignId: result.campaignId,
        selectionMode,
        timestamp: new Date().toISOString(),
      });

      setSuccessMessage(
        `Campaign sent successfully to ${result.recipientCount} recipients`
      );

      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      console.error(" Campaign send failed:", {
        error: error.message,
        selectionMode,
        timestamp: new Date().toISOString(),
      });
      setError(error.message || "Failed to send campaign. Please try again.");
    } finally {
      setIsSending(false);
    }
  };

  const handleSMSCampaignSubmit = async () => {
    try {
      console.log("🚀 Starting SMS campaign creation...", {
        campaignId,
        timestamp: new Date().toISOString(),
      });

      setError(null);
      setIsSending(true);

      if (!campaignName.trim()) {
        console.warn("❌ Validation failed: Campaign name is empty");
        throw new Error("Campaign name is required");
      }

      if (!phoneNumber.trim()) {
        console.warn("❌ Validation failed: Phone number is empty");
        throw new Error("Phone number is required");
      }

      // Validate phone number format
      const phoneRegex = /^\+?[1-9]\d{1,14}$/;
      if (!phoneRegex.test(phoneNumber.trim())) {
        console.warn("❌ Validation failed: Invalid phone number format", {
          phoneNumber: phoneNumber.trim(),
        });
        throw new Error("Invalid phone number format");
      }

      const campaignData = {
        id: campaignId,
        name: campaignName.trim(),
        phoneNumber: phoneNumber.trim(),
        agentId: agentId,
        type: "inbound",
      };

      console.log("📦 SMS Campaign data prepared:", {
        campaignId: campaignData.id,
        campaignName: campaignData.name,
        phoneNumber: campaignData.phoneNumber,
        agentId: campaignData.agentId,
        type: campaignData.type,
        timestamp: new Date().toISOString(),
      });

      const response = await campaignService.createSMSCampaign(campaignData);

      console.log("✅ SMS Campaign created successfully:", {
        campaignId: campaignData.id,
        response,
        timestamp: new Date().toISOString(),
      });

      setSuccessMessage("SMS Campaign created successfully");
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      console.error("❌ SMS Campaign creation failed:", {
        campaignId,
        error: error.message,
        stack: error.stack,
        timestamp: new Date().toISOString(),
      });
      setError(error.message || "Failed to create SMS campaign");
    } finally {
      setIsSending(false);
    }
  };

  const renderContactSelection = () => {
    if (!selectionMode) {
      return (
        <ContactSelectionButtons
          onSelectMode={setSelectionMode}
          containerStyles={slideOverStyles.contactSelectionButtons}
        />
      );
    }

    return selectionMode === "table" ? (
      <>
        <Button
          variant="text"
          sx={{ ml: 2 }}
          startIcon={<ArrowBack />}
          onClick={() => setSelectionMode(null)}
        >
          Back to selection
        </Button>
        <ContactsSelectionTable
          onNext={() => setActiveStep(1)}
          selectedContacts={selectedContacts}
          setSelectedContacts={setSelectedContacts}
        />
      </>
    ) : (
      <Box sx={{ p: 2 }}>
        <Button
          variant="text"
          startIcon={<ArrowBack />}
          onClick={() => setSelectionMode(null)}
          sx={{
            mb: 2,
            color: "#015d86",
          }}
        >
          <Typography sx={{ fontSize: "13px" }}>Back to selection</Typography>
        </Button>

        <Alert
          severity="info"
          icon={<Warning />}
          sx={{
            mb: 3,
            backgroundColor: "#e3f2fd",
            "& .MuiAlert-icon": {
              color: "#1976d2",
            },
            border: "1px solid #90caf9",
            borderRadius: "4px",
          }}
        >
          <Box>
            <Typography fontWeight="bold" sx={{ fontSize: "14px", mb: 0.5 }}>
              CSV Files Only
            </Typography>
            <Typography sx={{ fontSize: "13px" }}>
              Please note that only CSV (.csv) files are supported for contact
              imports. Other file formats will be rejected.
            </Typography>
          </Box>
        </Alert>
        <ContactImport
          onImportComplete={handleContactsImport}
          acceptedFileTypes={{
            "text/csv": [".csv"],
          }}
          onError={(error) => setError(error.message)}
        />

        {error && (
          <Alert
            severity="error"
            sx={{
              mt: 2,
              "& .MuiAlert-message": {
                fontSize: "13px",
              },
            }}
          >
            {error}
          </Alert>
        )}
      </Box>
    );
  };

  const getVariableOptions = () => {
    if (selectionMode === "table" && selectedContacts?.length > 0) {
      // For table selection, get fields from the contact object
      const excludedFields = ["metadata", "insert_date", "last_modified"];
      return Object.keys(selectedContacts[0] || {}).filter(
        (key) => !excludedFields.includes(key)
      );
    } else if (currentFile && mappedFields) {
      // For CSV import, use the mapped headers
      return csvHeaders;
    }
    return [];
  };

  const fetchUserDomains = async () => {
    try {
      const params = { id: customerFromClaim, status: "" };
      const response = await API.graphql({
        query: getCustomer,
        variables: params,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const currentUserCustomer = JSON.parse(response.data.getCustomer.body);

      const userDomains = currentUserCustomer.channels.EMAIL || [];
      const smsNumbers = currentUserCustomer.channels.SMS || [];
      
      console.log("Extracted channel data:", {
        userDomains,
        smsNumbers,
        domainsLength: userDomains.length,
        smsNumbersLength: smsNumbers.length,
        timestamp: new Date().toISOString()
      });

      setUserDomains(userDomains);
      setUserPhoneNumbers(smsNumbers);
      setDomain(userDomains[0]);
      if (smsNumbers.length > 0) {
        setPhoneNumber(smsNumbers[0]);
      }
      
      return userDomains;

    } catch (error) {
      console.error("Error fetching user data:", error);
      setUserDomains([]);
      setUserPhoneNumbers([]);
      setDomain("");
      throw new Error("Unable to load configuration. Please ensure domains and phone numbers are configured in your settings.");
    }
  };

  return open ? (
    <Box
      sx={{
        ...slideOverStyles.container,
        width: isEmail ? "50%" : "30%",
      }}
    >
      <Box sx={slideOverStyles.header}>
        {isEmail && activeStep === 1 && (
          <IconButton onClick={handleBack} size="small" sx={{ mr: 1 }}>
            <ArrowBack />
          </IconButton>
        )}
        <Typography sx={slideOverStyles.title}>
          {isEmail ? "Email Campaign" : "SMS Campaign"}
        </Typography>
        <IconButton onClick={onClose} size="small">
          <Close />
        </IconButton>
      </Box>

      {isEmail && (
        <Stepper activeStep={activeStep} sx={{ p: 2 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}

      {error && (
        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={() => setError(null)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => setError(null)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {error}
          </Alert>
        </Snackbar>
      )}

      {successMessage && (
        <Snackbar
          open={!!successMessage}
          autoHideDuration={4000}
          onClose={() => setSuccessMessage("")}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => setSuccessMessage("")}
            severity="success"
            sx={{ width: "100%" }}
          >
            {successMessage}
          </Alert>
        </Snackbar>
      )}

      <Box
        sx={
          selectionMode
            ? slideOverStyles.content
            : {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                ...slideOverStyles.content,
              }
        }
      >
        {isEmail ? (
          activeStep === 0 ? (
            renderContactSelection()
          ) : (
            <EmailCampaignForm
              senderName={senderName}
              onSenderNameChange={handleSenderNameChange}
              domain={domain}
              onDomainChange={handleDomainChange}
              senderEmail={senderEmail}
              onSenderEmailChange={handleSenderEmailChange}
              emailSubject={emailSubject}
              onEmailSubjectChange={(e) => setEmailSubject(e.target.value)}
              isEditorLoading={isEditorLoading}
              isSending={isSending}
              selectedContacts={selectedContacts}
              onEditorLoad={handleEditorLoad}
              emailEditorRef={emailEditorRef}
              onSendCampaign={handleSendCampaign}
              sampleDomainOptions={userDomains}
              slideOverStyles={slideOverStyles}
              variableOptions={getVariableOptions()}
              variables={variables}
              onSaveVariables={(variables) => {
                console.log("Saving variables with mappings:", {
                  variables,
                  mappedFields,
                });
                setVariables(variables);
              }}
              mappedFields={mappedFields}
            />
          )
        ) : (
          <SMSCampaignForm
            campaignName={campaignName}
            setCampaignName={setCampaignName}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            isSending={isSending}
            onSubmit={handleSMSCampaignSubmit}
            phoneNumbers={userPhoneNumbers}
          />
        )}
      </Box>
    </Box>
  ) : null;
};

export default CampaignSlideOver;
