import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {
  Alert,
  Box,
  Button,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CloudUpload, Check, Refresh } from "@mui/icons-material";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import { styles } from "./ContactImport.styles";

const REQUIRED_FIELDS = ["email"];
const OPTIONAL_FIELDS = ["name", "lastname", "address", "phone", "company"];
const ALL_FIELDS = [...REQUIRED_FIELDS, ...OPTIONAL_FIELDS];

const FIELD_LABELS = {
  email: "Email",
  name: "First Name",
  lastname: "Last Name",
  address: "Address",
  phone: "Phone Number",
  company: "Company",
};

const ContactImport = ({ onImportComplete, acceptedFileTypes, onError }) => {
  const [fileData, setFileData] = useState(null);
  const [mappedFields, setMappedFields] = useState({});
  const [availableFields, setAvailableFields] = useState([]);
  const [error, setError] = useState(null);
  const [importing, setImporting] = useState(false);
  const [preview, setPreview] = useState([]);

  const processFile = async (file) => {
    try {
      const reader = new FileReader();

      reader.onload = async (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(firstSheet, {
            header: 1,
            raw: false,
          });

          if (jsonData.length < 2) {
            throw new Error(
              "File must contain at least one header row and one data row"
            );
          }

          const headers = jsonData[0].map((header) =>
            header ? header.toString().trim() : ""
          );

          if (headers.length === 0) {
            throw new Error("No headers found in the file");
          }

          console.log("CSV Headers:", headers);

          const processedData = {
            file: file,
            jsonData: jsonData,
            headers: headers,
          };

          // Initialize mapping
          const initialMapping = {};
          ALL_FIELDS.forEach((field) => {
            initialMapping[field] = "";
          });

          // Auto-map fields based on common variations
          headers.forEach((header) => {
            const headerLower = header.toLowerCase();

            if (
              headerLower.includes("email") ||
              headerLower.includes("e-mail")
            ) {
              initialMapping.email = header;
            } else if (
              headerLower.includes("first") ||
              headerLower === "name" ||
              headerLower.includes("nombre")
            ) {
              initialMapping.name = header;
            } else if (
              headerLower.includes("last") ||
              headerLower.includes("surname") ||
              headerLower.includes("apellido")
            ) {
              initialMapping.lastname = header;
            } else if (
              headerLower.includes("phone") ||
              headerLower.includes("tel")
            ) {
              initialMapping.phone = header;
            } else if (
              headerLower.includes("company") ||
              headerLower.includes("empresa")
            ) {
              initialMapping.company = header;
            } else if (
              headerLower.includes("address") ||
              headerLower.includes("direccion")
            ) {
              initialMapping.address = header;
            }
          });

          setFileData(processedData);
          setAvailableFields(headers);
          setMappedFields(initialMapping);
          setPreview(jsonData.slice(1, 4));
        } catch (error) {
          console.error("Error processing file:", error);
          setError(error.message);
          onError?.(error);
        }
      };

      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.error("Error reading file:", error);
      setError(error.message);
      onError?.(error);
    }
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setError(null);

      if (acceptedFiles && acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        processFile(file);
      }
    },
    [onError]
  );

  const handleImport = () => {
    if (!fileData) return;

    setImporting(true);
    setError(null);

    try {
      const { jsonData, headers } = fileData;
      const contacts = [];

      // Process each row starting from index 1 (skip headers)
      for (let i = 1; i < jsonData.length; i++) {
        const row = jsonData[i];
        const contact = {};

        // Map fields according to user's mapping
        Object.entries(mappedFields).forEach(([systemField, csvField]) => {
          if (csvField) {
            const columnIndex = headers.indexOf(csvField);
            if (columnIndex !== -1) {
              contact[systemField] = row[columnIndex] || "";
            }
          }
        });

        // Only add contacts that have at least an email
        if (contact.email) {
          contacts.push(contact);
        }
      }

      if (contacts.length === 0) {
        throw new Error("No valid contacts found in the file");
      }

      onImportComplete(contacts, fileData.file, mappedFields);
      setFileData(null);
      setMappedFields({});
      setPreview([]);
    } catch (error) {
      console.error("Import error:", error);
      setError(error.message);
      onError?.(error);
    } finally {
      setImporting(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileTypes || {
      "text/csv": [".csv"],
      "application/vnd.ms-excel": [".csv"],
    },
    multiple: false,
  });

  return (
    <Box sx={styles.container}>
      {!fileData ? (
        <Paper
          {...getRootProps()}
          sx={{
            ...styles.dropZone,
            ...(isDragActive && styles.dropZoneActive),
          }}
        >
          <input {...getInputProps()} />
          <CloudUpload sx={styles.uploadIcon} />
          <Typography variant="body1" sx={{ mb: 1 }}>
            {isDragActive
              ? "Drop the file here"
              : "Drag and drop a file here, or click to select"}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Supported formats: .csv
          </Typography>
        </Paper>
      ) : (
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle2" fontWeight="bold" sx={{ mb: 2 }}>
            Map Your Fields
          </Typography>
          {ALL_FIELDS.map((field) => (
            <FormControl key={field} fullWidth sx={{ mb: 2 }}>
              <Typography variant="subtitle2" sx={{ mb: 1 }}>
                {FIELD_LABELS[field]} {field === "email" && "*"}
              </Typography>
              <Select
                value={mappedFields[field] || ""}
                onChange={(e) => {
                  setMappedFields((prev) => ({
                    ...prev,
                    [field]: e.target.value,
                  }));
                }}
                size="small"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {availableFields.map((header) => (
                  <MenuItem key={header} value={header}>
                    {header}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ))}

          {preview.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2" sx={{ mb: 1 }}>
                Preview
              </Typography>
              <Paper sx={{ p: 2 }}>
                {preview.map((row, idx) => (
                  <Typography key={idx} variant="body2" sx={{ mb: 1 }}>
                    {row.join(", ")}
                  </Typography>
                ))}
              </Paper>
            </Box>
          )}

          <Button
            variant="contained"
            onClick={handleImport}
            disabled={importing || !mappedFields.email}
            sx={{
              mt: 2,
              backgroundColor: "#015d86",
              "&:hover": { backgroundColor: "#014a6d" },
            }}
          >
            {importing ? "Importing..." : "Import Contacts"}
          </Button>
        </Box>
      )}

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </Box>
  );
};

export default ContactImport;
