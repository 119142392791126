import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { API } from "aws-amplify";
import { Auth } from "aws-amplify";
import { GridLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPlus } from "@fortawesome/free-solid-svg-icons";
import { getCampaign } from "../../graphql/queries";
import { COLUMN_CAMPAIGNS } from "./utils/constants";
import DataTable from "../../components/DataTable/DataTable";
import { useNavigate } from "react-router-dom";
import CreateCampaignDialog from "./components/CreateCampaignDialog";

const Campaigns = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [campaigns, setCampaigns] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState("All");
  const [searchFocused, setSearchFocused] = useState(false);
  const [campaignsLoaded, setCampaignsLoaded] = useState(false);
  const [customerFromClaim, setCustomer] = useState(null);
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  const [alert, setAlert] = useState({ message: null, severity: "error" });
  const [isCustomerLoading, setIsCustomerLoading] = useState(true);

  useEffect(() => {
    const checkCustomer = async () => {
      setIsCustomerLoading(true);
      try {
        const userInfo = await Auth.currentAuthenticatedUser();
        const customer = userInfo.attributes["custom:account"];
        console.log("Customer fetched:", customer);
        setCustomer(customer);
      } catch (e) {
        console.error("Error getting customer:", e);
      } finally {
        setIsCustomerLoading(false);
      }
    };

    checkCustomer();
  }, []);

  useEffect(() => {
    if (!isCustomerLoading && customerFromClaim && !campaignsLoaded) {
      console.log("Fetching campaigns for customer:", customerFromClaim);
      getCampaigns();
    }
  }, [isCustomerLoading, campaignsLoaded, customerFromClaim]);

  const getCampaigns = async () => {
    try {
      const fetchedCampaigns = await fetchCampaigns();
      console.log("fetchedCampaigns: ", fetchedCampaigns);
      setCampaigns(fetchedCampaigns || []);
      setFilteredCampaigns(fetchedCampaigns || []);
      setCampaignsLoaded(true);
    } catch (error) {
      console.error("Campaign fetch error details:", {
        error: error,
        message: error.message,
        stack: error.stack,
        customerFromClaim: customerFromClaim
      });
      handleError("An error occurred while fetching campaigns.");
    }
  };
  const fetchCampaigns = async () => {
    const params = { customer: customerFromClaim, status: "" };
    try {
      const response = await API.graphql({
        query: getCampaign,
        variables: params,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      return JSON.parse(response.data.getCampaign.body);
    } catch (error) {
      throw new Error("Error fetching campaigns.");
    }
  };

  const handleAlert = (message, severity) => {
    setAlert({ message, severity });
  };

  const handleError = (errorMessage) => {
    handleAlert(errorMessage, "error");
  };

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearch(searchTerm);

    // Improved search filtering
    const filtered = campaigns.filter((campaign) => {
      const campaignName = campaign?.metadata?.name || '';
      const campaignId = campaign?.id || '';
      const searchLower = searchTerm.toLowerCase();
      
      return campaignName.toLowerCase().includes(searchLower) || 
             campaignId.toLowerCase().includes(searchLower);
    });
    setFilteredCampaigns(filtered);
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const filterCampaignsByTab = () => {
    const campaignsToDisplay =
      selectedTab === "All"
        ? filteredCampaigns
        : filteredCampaigns.filter(
            (campaign) => campaign?.metadata?.type === selectedTab.toLowerCase()
          );

    return campaignsToDisplay
      .map((campaign) => ({
        ...campaign,
        formattedInsertDate: formatDateTime(campaign.insert_date),
      }))
      .sort((a, b) => {
        const dateA = new Date(a.insert_date);
        const dateB = new Date(b.insert_date);
        return dateB - dateA; 
      });
  };

  const handleCreateCampaign = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleNavigateToPlayground = () => {
    navigate("/playground");
  };

  return (
    <Box p={3}>
      <Box
        mb={2}
        display="flex"
        alignItems="center"
        sx={{ marginBottom: "4vh" }}
        justifyContent="space-between"
      >
        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          Campaigns
        </Typography>
      </Box>
      {isCustomerLoading || !campaignsLoaded ? (
        <Box
          sx={{
            display: "flex",
            marginTop: "20%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <GridLoader />
        </Box>
      ) : (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <TextField
              value={search}
              variant="outlined"
              placeholder="Search"
              onChange={handleSearchChange}
              onFocus={() => setSearchFocused(true)}
              onBlur={() => setSearchFocused(false)}
              sx={{
                marginRight: "40px",
                marginBottom: "10px",
                borderRadius: "25px",
                "& .MuiOutlinedInput-root": {
                  width: "300%",
                  height: "36px",
                  fontSize: "13px",
                  borderRadius: "20px",
                  "&:hover fieldset": { borderColor: "black" },
                  "&.Mui-focused fieldset": { borderColor: "black" },
                },
              }}
              InputProps={{
                startAdornment: !searchFocused && (
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    style={{
                      fontSize: "16px",
                      marginLeft: "5px",
                      marginRight: "7px",
                    }}
                  />
                ),
                sx: {
                  fontSize: "13px",
                  paddingLeft: "8px",
                  borderRadius: "25px",
                },
              }}
            />
            <Box display="flex" sx={{ gap: "15px" }}>
              <Button
                variant="contained"
                onClick={handleCreateCampaign}
                sx={{
                  marginBottom: "20px",
                  textTransform: "none",
                  backgroundColor: "#015d86",
                  "&:hover": { backgroundColor: "#1a749c" },
                }}
                startIcon={
                  <FontAwesomeIcon icon={faPlus} style={{ fontSize: "14px" }} />
                }
              >
                <Typography sx={{ fontSize: "14px" }}>
                  Create new campaign
                </Typography>
              </Button>
            </Box>
          </Box>
          <Tabs
            value={selectedTab}
            onChange={(e, newValue) => setSelectedTab(newValue)}
            aria-label="campaign tabs"
          >
            <Tab
              value="All"
              label={
                <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
                  All
                </Typography>
              }
            />
            <Tab
              value="Email"
              label={
                <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Email
                </Typography>
              }
            />
            <Tab
              value="SMS"
              label={
                <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
                  SMS
                </Typography>
              }
            />
          </Tabs>
          <Typography variant="subtitle1" sx={{ margin: "1vh 0vh 1vh 0vh" }}>
            <strong>{filterCampaignsByTab().length}</strong> in total
          </Typography>
          <DataTable
            type={"campaigns"}
            data={filterCampaignsByTab()}
            columns={COLUMN_CAMPAIGNS}
            onEdit={() => {}}
            onDelete={() => {}}
          />
        </>
      )}
      <Snackbar
        open={!!alert.message}
        autoHideDuration={6000}
        onClose={() => setAlert({ message: null })}
      >
        <Alert
          onClose={() => setAlert({ message: null })}
          severity={alert.severity}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <CreateCampaignDialog
        open={openModal}
        onClose={handleModalClose}
        onConfirm={handleNavigateToPlayground}
      />
    </Box>
  );
};

export default Campaigns;
