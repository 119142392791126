export const slideOverStyles = {
  container: {
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 1200,
    position: "fixed",
    background: "#fff",
    borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
    transform: (open) => (open ? "translateX(0)" : "translateX(100%)"),
    transition: "all 0.3s ease-in-out",
    boxShadow: "-4px 0 6px -1px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    p: 2,
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: "#fff",
    zIndex: 1,
  },
  title: {
    fontSize: "16px",
    fontWeight: 600,
    flex: 1,
  },
  content: {
    flexGrow: 1,
    overflow: "auto",
    height: "calc(100% - 120px)",
    position: "relative",
  },
  loaderContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    zIndex: 1,
  },
  textField: {
    "& .MuiInputBase-root": {
      height: "36px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": { borderColor: "black" },
      "&:hover fieldset": { borderColor: "black" },
      "&.Mui-focused fieldset": { borderColor: "black" },
    },
  },
  formSection: {
    mb: 3,
  },
  infoBox: {
    p: 2,
    mb: 3,
    borderRadius: 1,
    backgroundColor: "#f5f5f5",
    border: "1px solid #e0e0e0",
  },
  infoTitle: {
    mb: 1,
    color: "#015d86",
    fontWeight: "bold",
  },
  infoText: {
    fontSize: "12px",
    color: "#666",
    lineHeight: 1.5,
  },
  submitButton: {
    position: "absolute",
    bottom: 16,
    right: 16,
    backgroundColor: "#015d86",
    "&:hover": {
      backgroundColor: "#014a6d",
    },
  },
  labelProps: {
    mt: "-5px",
    fontSize: "12px",
  },
  formContainer: {
    p: 2,
  },
  formTitle: {
    mb: 1,
    fontWeight: "bold",
  },
  emailFormSection: {
    gap: 2,
    display: "flex",
    justifyContent: "space-between",
  },
  emailFormColumn: {
    display: "flex",
    flexDirection: "column",
  },
  contactImportSection: {
    borderTop: "1px solid #eee",
    pt: 2,
    mt: 2,
  },
  contactSelectionButtons: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    alignItems: "center",
    "& .MuiButton-root": {
      margin: "8px 0",
    },
  },
};
