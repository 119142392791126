import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { Storage } from "aws-amplify";
import useStore from "../../hooks/useStore";
import DataFetcher from "../../utils/DataFetcher";
import ChatBox from "../../components/ChatBox/ChatBox";
import ChatWindow from "../../components/ChatWindow/ChatWindow";
import { Auth } from "aws-amplify";

const BEDROCK_KB_ID = process.env.REACT_APP_BEDROCK_KB_ID;

const AdminAssistant = ({ isDarkMode }) => {
  const { authStore } = useStore();
  const dataFetcher = DataFetcher();
  const [files, setFiles] = useState([]);
  const [userId, setUserId] = useState("");
  const [modelType, setModelType] = useState("");
  const [newMessage, setNewMessage] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [uploadedStatus, setUploadedStatus] = useState("");
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [customerFromClaim, setCustomer] = useState(null);

  const [messages, setMessages] = useState(() => {
    const savedMessages = localStorage.getItem("AdminChatMessages");
    return savedMessages ? JSON.parse(savedMessages) : [];
  });

  useEffect(() => {
    if (!authStore.userId) return;

    const initialize = async () => {
      const applicantId = authStore.userId;
      const getFiles = await dataFetcher.fetchFiles(applicantId);
      setUserId(applicantId);
      setFiles(getFiles);
    };

    initialize();
  }, [authStore.userId]);

  useEffect(() => {
    localStorage.setItem("AdminChatMessages", JSON.stringify(messages));
  }, [messages]);

  useEffect(() => {
    const checkCustomer = async () => {
      try {
        const userInfo = await Auth.currentAuthenticatedUser();
        const customer = userInfo.attributes["custom:account"];
        setCustomer(customer);
      } catch (e) {
        console.log("No current user", e);
      }
    };

    checkCustomer();
  }, [customerFromClaim]);

  const uploadFile = useCallback(
    async (event) => {
      setIsUploadingFile(true);
      const uploadedFiles = await handleFileUpload(event.target.files, userId);
      if (!_.isEmpty(uploadedFiles)) {
        await dataFetcher.saveFilesToDatabase(
          uploadedFiles,
          userId,
          customerFromClaim
        );
        if (uploadedFiles.length) {
          setUploadedStatus("success");
        }
      }
      setIsUploadingFile(false);
    },
    [dataFetcher, userId, customerFromClaim]
  );

  const handleFileUpload = async (files, applicantId) => {
    try {
      const uploadedFiles = await Promise.all(
        Array.from(files).map(async (file) => {
          const fileId = file.name.split("-")[0];
          const filePath = `internship/${applicantId}/${file.name}`;
          const result = await Storage.put(filePath, file, {
            contentType: file.type,
          });
          console.log(`File uploaded: ${JSON.stringify(result)}`);
          return {
            file_name: file.name,
            file_description: "file description",
            s3_path: `public/${result.key}`,
            file_id: fileId,
          };
        })
      );
      return uploadedFiles;
    } catch (error) {
      setUploadedStatus("error");
      throw new Error("Error uploading files:", error);
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === "") return;

    const newSentMessage = { message: newMessage, type: "sent" };
    const newLoadingMessage = {
      message: "",
      type: "received",
      isLoading: true,
    };

    setMessages((prevMessages) => [
      ...prevMessages,
      newSentMessage,
      newLoadingMessage,
    ]);

    setShowSpinner(true);

    const handleApiError = (messages) => {
      return messages.map((msg) =>
        msg.isLoading
          ? {
              ...msg,
              message: "Oops! Something went wrong. Please try again.",
              isLoading: false,
            }
          : msg
      );
    };

    let local_msgs = [];

    try {
      let retries = 2;
      local_msgs = _.union(messages, []);
      const params = {
        bedrock_kb_id: BEDROCK_KB_ID,
        message: newMessage,
        model: modelType || "INSTANT",
        email_address: userId,
      };
      console.log("params: ", params);
      const assistantAnswer = await dataFetcher.fetchLangChainAssistantData(
        params,
        retries,
        true
      );
      local_msgs = _.union(local_msgs, [newSentMessage, newLoadingMessage]);
      let initialMessages = [...local_msgs];
      const newMessages = initialMessages.map((msg) =>
        msg.isLoading
          ? {
              ...msg,
              message: assistantAnswer,
              isLoading: false,
            }
          : msg
      );

      setNewMessage("");
      setShowSpinner(false);
      setMessages(newMessages);
    } catch (err) {
      console.log(err);
      setShowSpinner(false);
      setMessages(handleApiError(local_msgs));
    }
  };

  const handleClearMessages = () => {
    setMessages([]);
    localStorage.removeItem("AdminChatMessages");
  };

  const handleModelType = (type) => {
    setModelType(type);
  };

  return (
    <>
      <ChatWindow isDarkMode={isDarkMode} messages={messages} />
      <ChatBox
        isDarkMode={isDarkMode}
        files={files}
        isAdmin={true}
        isGroqAgent={true}
        showSpinner={showSpinner}
        currentMessage={newMessage}
        uploadedStatus={uploadedStatus}
        isUploadingFile={isUploadingFile}
        onUploadFile={uploadFile}
        onUpdateMessages={(message) => setNewMessage(message)}
        onSendMessage={handleSendMessage}
        clearMessages={handleClearMessages}
        handleModelType={handleModelType}
      />
    </>
  );
};

export default AdminAssistant;
