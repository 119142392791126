export const styles = {
  container: {
    p: 2,
    maxHeight: "100%",
    overflow: "auto",
  },
  dropZone: {
    p: 2,
    textAlign: "center",
    cursor: "pointer",
    border: "2px dashed #ccc",
    minHeight: "100px",
    transition: "all 0.3s ease",
    "&:hover": {
      borderColor: "#2196f3",
      backgroundColor: "#f0f7ff",
    },
  },
  dropZoneActive: {
    backgroundColor: "#f0f7ff",
  },
  uploadIcon: {
    fontSize: 32,
    color: "primary.main",
    mb: 1,
  },
  mappingContainer: {
    overflow: "auto",
  },
  previewContainer: {
    mb: 2,
  },
  previewPaper: {
    p: 1,
    maxHeight: "100px",
    overflow: "auto",
    backgroundColor: "#f5f5f5",
    fontSize: "0.75rem",
  },
  previewRow: {
    mb: 0.5,
  },
  buttonContainer: {
    display: "flex",
    gap: 1,
  },
  successAlert: {
    p: 2,
    textAlign: "center",
  },
};
