import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { Auth } from "aws-amplify";
import { GridLoader } from "react-spinners";
import {
  Box,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { getContact } from "../../../graphql/queries";
import DataTable from "../../../components/DataTable/DataTable";
import { COLUMN_CONTACTS } from "../../Contacts/utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

const MAX_CONTACTS = 45;

const ContactsSelectionTable = ({
  onNext,
  selectedContacts,
  setSelectedContacts,
}) => {
  const [contacts, setContacts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchFocused, setSearchFocused] = useState(false);
  const [contactsLoaded, setContactsLoaded] = useState(false);
  const [customerFromClaim, setCustomer] = useState(false);
  const [showLimitDialog, setShowLimitDialog] = useState(false);

  useEffect(() => {
    if (!contactsLoaded) {
      getContactsList(customerFromClaim);
    }
  }, [contactsLoaded, customerFromClaim]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredContacts = contacts.filter((contact) =>
    contact.name.toLowerCase().includes(searchTerm?.toLowerCase() || "")
  );

  useEffect(() => {
    console.log("ContactsSelectionTable - Props received:", {
      selectedContacts,
      hasSetSelectedContacts: !!setSelectedContacts,
    });
  }, [selectedContacts]);

  useEffect(() => {
    const checkCustomer = async () => {
      try {
        const userInfo = await Auth.currentAuthenticatedUser();
        const customer = userInfo.attributes["custom:account"];
        setCustomer(customer);
      } catch (e) {
        console.log("No current user", e);
      }
    };

    checkCustomer();
  }, [customerFromClaim]);

  useEffect(() => {
    console.log("DataTable props:", {
      type: "contacts",
      dataLength: contacts.length,
      selectedRowsLength: selectedContacts?.length,
      hasOnSelect: !!handleContactSelect,
      hasMultiSelect: true,
      hasCheckboxes: true,
    });
  }, [contacts, selectedContacts]);

  const getContactsList = async (customerFromClaim) => {
    try {
      const params = { id: "", customer: customerFromClaim };
      const response = await API.graphql({
        query: getContact,
        variables: params,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const rawContacts = JSON.parse(response.data.getContact.body);

      const fetchedContacts = rawContacts.map((contact) => ({
        ...contact,
        id: contact.id || String(Math.random()),
        name:
          contact.name ||
          `${contact.firstname || ""} ${contact.lastname || ""}`.trim(),
        email: contact.email || "",
        phone: contact.phone || "",
      }));

      console.log("Contacts fetched successfully:", {
        total: fetchedContacts.length,
        sample: fetchedContacts[0],
      });

      setContacts(fetchedContacts);
      setContactsLoaded(true);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  const handleContactSelect = (contact) => {
    if (Array.isArray(contact)) {
      // Handle select all case
      if (contact.length > MAX_CONTACTS) {
        setShowLimitDialog(true);
        return;
      }
      const validContacts = contact.filter((c) => c?.email);
      setSelectedContacts(validContacts);
      return;
    }

    if (!contact?.email) {
      console.warn("Contact missing email:", contact);
      return;
    }

    setSelectedContacts((prev) => {
      const currentSelection = Array.isArray(prev) ? prev : [];
      const isSelected = currentSelection.some((c) => c.id === contact.id);

      if (!isSelected && currentSelection.length >= MAX_CONTACTS) {
        setShowLimitDialog(true);
        return currentSelection;
      }

      const newSelection = isSelected
        ? currentSelection.filter((c) => c.id !== contact.id)
        : [...currentSelection, contact];

      return newSelection;
    });
  };

  return (
    <Box sx={{ p: 2, height: "100%" }}>
      {contactsLoaded ? (
        <>
          <TextField
            value={searchTerm}
            variant="outlined"
            placeholder="Search"
            onChange={handleSearch}
            onFocus={() => setSearchFocused(true)}
            onBlur={() => setSearchFocused(false)}
            sx={{
              marginTop: "-5px",
              marginRight: "40px",
              marginBottom: "10px",
              borderRadius: "25px",
              "& .MuiOutlinedInput-root": {
                width: "300%",
                height: "36px",
                fontSize: "13px",
                borderRadius: "20px",
                "&:hover fieldset": { borderColor: "black" },
                "&.Mui-focused fieldset": { borderColor: "black" },
              },
            }}
            InputProps={{
              startAdornment: !searchFocused && (
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  style={{
                    fontSize: "16px",
                    marginLeft: "5px",
                    marginRight: "7px",
                  }}
                />
              ),
              sx: {
                fontSize: "13px",
                paddingLeft: "8px",
                borderRadius: "25px",
              },
            }}
          />
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            Selected contacts: <strong>{selectedContacts?.length || 0}</strong>
          </Typography>
          <DataTable
            type="contacts"
            data={filteredContacts}
            columns={COLUMN_CONTACTS}
            onSelect={handleContactSelect}
            selectedRows={selectedContacts || []}
            selectable={true}
            showCheckboxes={true}
            multiSelect={true}
          />
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              onClick={() => {
                console.log(
                  "Next button clicked with contacts:",
                  selectedContacts
                );
                onNext();
              }}
              disabled={!selectedContacts || selectedContacts.length === 0}
              sx={{
                backgroundColor: "#015d86",
                "&:hover": { backgroundColor: "#014a6d" },
                "&:disabled": {
                  backgroundColor: "#cccccc",
                  cursor: "not-allowed",
                },
                textTransform: "none",
                fontSize: "13px",
              }}
            >
              Next
            </Button>
          </Box>
        </>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <GridLoader />
        </Box>
      )}

      <Dialog
        open={showLimitDialog}
        onClose={() => setShowLimitDialog(false)}
        PaperProps={{
          sx: {
            width: "400px",
            padding: "16px",
          },
        }}
      >
        <DialogTitle sx={{ fontSize: "16px", fontWeight: "bold" }}>
          Contact Limit Reached
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ fontSize: "14px" }}>
            You can select up to {MAX_CONTACTS} contacts in the table view. For
            campaigns with more contacts, please use the CSV import option.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowLimitDialog(false)}
            sx={{
              backgroundColor: "#015d86",
              color: "white",
              "&:hover": { backgroundColor: "#014a6d" },
              textTransform: "none",
              fontSize: "13px",
            }}
          >
            Got it
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ContactsSelectionTable;
