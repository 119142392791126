import { useCallback } from "react";
import { API, Auth } from "aws-amplify";
import {
  getAgent,
  getFiles,
  getLangchainProgramConversation,
  putFiles,
} from "../graphql/queries";
import _ from "lodash";
const ENV = _.isUndefined(process.env.REACT_APP_USER_BRANCH)
  ? "dev"
  : process.env.REACT_APP_USER_BRANCH;
const handleError = (error, fallbackValue, errorMessage) => {
  console.error(errorMessage, error);
  // You could also use a centralized error logging service here
  return fallbackValue;
};
const DataFetcher = () => {
  const fetchAgents = async (applicantId, customer_id) => {
    const params = {
      customer_id: customer_id,
      domain: applicantId,
      provider: "",
      group: false,
    };
    const response = await API.graphql({
      query: getAgent,
      variables: params,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    const fetchAgents = JSON.parse(response.data.getAgent.body);
    console.log("fetchAgents: ", fetchAgents);
    return fetchAgents;
  };
  const fetchUserId = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return user;
    } catch (error) {
      throw new Error("Error fetching user ID:", error);
    }
  };
  const fetchFiles = async (applicantId) => {
    try {
      const response = await API.graphql({
        query: getFiles,
        variables: { applicant_id: applicantId },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const fetchedFiles = JSON.parse(response.data.getFiles.body);
      console.log("Fetched files:", fetchedFiles);
      return fetchedFiles;
    } catch (error) {
      throw new Error("Error fetching files:", error);
    }
  };
  // const getPromptsData = useCallback(async (retries) => {
  //   const params = {
  //     query: "",
  //     is_public: false,
  //   };
  //   try {
  //     const response = await API.graphql({
  //       query: getPrompts,
  //       variables: params,
  //       authMode: "AMAZON_COGNITO_USER_POOLS",
  //     });
  //     const fetchedPrompts = JSON.parse(response.data.getPrompts.body);
  //     if (fetchedPrompts.error) {
  //       if (retries > 0) {
  //         console.log(`First attempt failed, retry # ${retries}...`);
  //         await new Promise((resolve) => setTimeout(resolve, 5000));
  //         return getPromptsData(retries - 1);
  //       }
  //     }
  //     console.log("fetchedPrompts: ", fetchedPrompts);
  //     return fetchedPrompts;
  //   } catch (error) {
  //     console.error("Error fetching prompts:", error);
  //     return [];
  //   }
  // }, []);
  // const processPrompt = async (currentPrompt) => {
  //   const repoHandle = currentPrompt?.repo_handle;
  //   const { prompt, errors } = await pullPromptData(repoHandle);
  //   if (errors) return errors;
  //   const lastMessage = getLastMessage(prompt);
  //   const promptCommits = await fetchPromptCommits(repoHandle);
  //   return {
  //     name: repoHandle,
  //     instructions: lastMessage,
  //     description: currentPrompt?.description,
  //     tags: currentPrompt?.tags,
  //     inputVariables: mapInputVariables(prompt),
  //     commits: promptCommits,
  //   };
  // };
  // const fetchPrompts = useCallback(
  //   async (retries) => {
  //     try {
  //       const fetchedPrompts = await getPromptsData(retries);
  //       if (fetchedPrompts?.errors) return fetchedPrompts?.errors;
  //       const prompts = fetchedPrompts?.repos || [];
  //       const validPrompts = await Promise.all(prompts?.map(processPrompt));
  //       return validPrompts;
  //     } catch (error) {
  //       return handleError(error, [], "Error fetching prompts:");
  //     }
  //   },
  //   [getPromptsData]
  // );
  // const fetchPromptCommits = async (repoHandle) => {
  //   const params = { prompt_identifier: repoHandle };
  //   try {
  //     const response = await API.graphql({
  //       query: listPromptCommits,
  //       variables: params,
  //       authMode: "AMAZON_COGNITO_USER_POOLS",
  //     });
  //     const commits = JSON.parse(response?.data?.listPromptCommits?.body);
  //     if (commits?.error) return [];
  //     return Promise.all(
  //       commits?.map((commit, index) => processCommit(commit, index))
  //     );
  //   } catch (error) {
  //     console.error("Error fetching prompt commits:", error);
  //     return [];
  //   }
  // };
  // const processCommit = async (commit, index) => {
  //   const { commit_hash, repo } = commit;
  //   const { prompt } = await pullPromptData(repo, commit_hash);
  //   const lastMessage = getLastMessage(prompt);
  //   return {
  //     commitIndex: index + 1,
  //     template: lastMessage,
  //     inputVariables: prompt.input_variables || [],
  //     promptName: repo,
  //   };
  // };
  // const getLastMessage = (prompt) =>
  //   prompt?.messages?.[prompt.messages.length - 1]?.prompt?.template || "";
  // const mapInputVariables = (prompt) =>
  //   prompt?.input_variables?.map((variable) => ({
  //     name: variable,
  //     value: variable,
  //   })) || [];
  // const pullPromptData = useCallback(async (name, commitHash) => {
  //   const params = {
  //     name: name,
  //     commit_hash: commitHash || "",
  //     include_model: false,
  //   };
  //   try {
  //     const response = await API.graphql({
  //       query: pullPrompt,
  //       variables: params,
  //       authMode: "AMAZON_COGNITO_USER_POOLS",
  //     });
  //     const fetchedPrompt = JSON.parse(response.data.pullPrompt.body);
  //     return fetchedPrompt;
  //   } catch (error) {
  //     console.error("Error fetching prompts:", error);
  //     return error;
  //   }
  // }, []);
  const saveFilesToDatabase = async (uploadedFiles, applicantId, customer_id) => {
    try {
      console.log("uploadedFiles: ", uploadedFiles);
      console.log("applicantId: ", applicantId);
      const response = await API.graphql({
        query: putFiles,
        variables: {
          user_id: applicantId,
          customer: customer_id,
          s3_bucket: `${ENV.toLowerCase()}-cai3p0-integrations`,
          files: uploadedFiles,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      console.log("Response from putFiles:", response);
    } catch (error) {
      console.error("Error saving files to database:", error);
    }
  };
  const fetchLangChainAssistantData = async (
    params,
    retries,
    isFirstAttempt
  ) => {
    try {
      const response = await API.graphql({
        query: getLangchainProgramConversation,
        variables: params,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const responseBody = response.data.getLangchainProgramConversation.body;
      const start = responseBody.indexOf("answer=") + "answer=".length;
      const end = responseBody.lastIndexOf("}");
      const parsedResponse = responseBody.substring(start, end).trim();
      if (parsedResponse) {
        return parsedResponse;
      } else {
        throw new Error("No response");
      }
    } catch (error) {
      if (retries > 0) {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        return fetchLangChainAssistantData(params, retries - 1, false);
      } else {
        return isFirstAttempt
          ? "I am out of the office I will be back soon"
          : "Unable to process request.";
      }
    }
  };
  return {
    fetchAgents,
    fetchUserId,
    fetchFiles,
    // fetchPrompts,
    // fetchPromptCommits,
    // pullPromptData,
    saveFilesToDatabase,
    fetchLangChainAssistantData,
  };
};
export default DataFetcher;