import React, { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  faChevronDown,
  faChevronUp,
  faCodeBranch,
  faGears,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Scrollbars } from "react-custom-scrollbars-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PromptList = ({ prompts, onPromptSelect }) => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const ADD_BUTTON_STYLES = {
    minWidth: "150px",
    alignSelf: "flex-end",
    textTransform: "none",
    backgroundColor: "#015d86",
    "&:hover": { backgroundColor: "#1a749c" },
  };

  const PROMPT_ITEM_STYLES = {
    mb: 1,
    borderRadius: "8px",
    border: "1px solid #ccc",
    backgroundColor: "#f0f0f0",
    transition: "background-color 0.1s ease, transform 0.1s",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(0.98)",
      backgroundColor: "#e0e0e0",
    },
    "&:active": {
      backgroundColor: "#d0d0d0",
    },
  };

  const COMMIT_ITEM_STYLES = {
    mb: 1,
    ml: 2,
    width: "94%",
    height: "30px",
    borderRadius: "6px",
    backgroundColor: "#e0e0e0",
    transition: "background-color 0.3s ease, transform 0.2s",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(0.92)",
      backgroundColor: "#d0d0d0",
    },
    "&:active": {
      backgroundColor: "#c0c0c0",
    },
  };

  const TEXT_STYLES = {
    display: "block",
    marginTop: "4px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  };

  const formatString = (input) => {
    const normalizedString = input.replace(/[_-]/g, " ");

    const formattedString = normalizedString
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return formattedString;
  };

  const handleSelectCommit = (commit) => {
    const currentPrompt = prompts.find(
      (prompt) => prompt.name === commit.promptName
    );

    const selectedPrompt = {
      ...currentPrompt,
      name: currentPrompt.name,
      instructions: commit?.template,
      inputVariables:
        commit?.inputVariables?.map((variable) => ({
          name: variable,
          value: variable,
        })) || [],
      currentCommit: `(Commit ${commit?.commitIndex})`,
    };
    onPromptSelect(selectedPrompt);
  };

  return (
    <Box
      gap={2}
      height={isMobileOrTablet ? "500px" : "100%"}
      display="flex"
      overflow="hidden"
      flexDirection="column"
    >
      <Button
        variant="contained"
        sx={ADD_BUTTON_STYLES}
        startIcon={
          <FontAwesomeIcon icon={faPlus} style={{ fontSize: "16px" }} />
        }
        onClick={() => onPromptSelect(null)}
      >
        <Typography sx={{ fontSize: "14px", marginTop: "1px" }}>
          Add Prompt
        </Typography>
      </Button>
      <Scrollbars style={{ height: "100%" }}>
        <List sx={{ width: "95%" }}>
          {prompts && prompts.length > 0 ? (
            prompts?.map((prompt, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                <ListItem
                  onClick={() => {
                    onPromptSelect(prompt);
                  }}
                  sx={PROMPT_ITEM_STYLES}
                >
                  <FontAwesomeIcon
                    icon={faGears}
                    style={{ fontSize: "20px", marginRight: "15px" }}
                  />
                  <ListItemText
                    primary={
                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        sx={TEXT_STYLES}
                      >
                        {formatString(prompt.prompt_name)}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        sx={TEXT_STYLES}
                      >
                        {prompt.prompt_description}
                      </Typography>
                    }
                  />
                  {prompt?.commits?.length > 0 && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleToggleExpand(index);
                      }}
                      edge="end"
                    >
                      <FontAwesomeIcon
                        icon={
                          expandedIndex === index ? faChevronUp : faChevronDown
                        }
                        style={{ fontSize: "16px", marginLeft: "2px" }}
                      />
                    </IconButton>
                  )}
                </ListItem>
                {expandedIndex === index &&
                  prompt?.commits?.map((commit) => (
                    <ListItem
                      key={commit.commitIndex}
                      sx={COMMIT_ITEM_STYLES}
                      onClick={() => {
                        handleSelectCommit(commit);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCodeBranch}
                        style={{ fontSize: "13px", marginRight: "6px" }}
                      />
                      <ListItemText
                        primary={
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            sx={TEXT_STYLES}
                          >
                            {`Commit ${commit.commitIndex}`}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
              </Box>
            ))
          ) : (
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ textAlign: "center", marginTop: "20px" }}
            >
              No prompts found. Add a new prompt to get started.
            </Typography>
          )}
        </List>
      </Scrollbars>
    </Box>
  );
};

export default PromptList;
