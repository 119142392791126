import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const CreateCampaignDialog = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <FontAwesomeIcon
          icon={faCircleInfo}
          style={{ color: "#015d86", marginRight: "10px" }}
        />
        {"Create New Campaign"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You will be redirected to the Playground where you can create or
          select an agent and click the "Start Campaign" button to begin
          creating your campaign.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{ color: "#015d86" }}>
          Cancel
        </Button>
        <Button onClick={onConfirm} sx={{ color: "#015d86" }} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCampaignDialog;
