/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getConversationLLama2Chat = /* GraphQL */ `
  query GetConversationLLama2Chat(
    $agent: String
    $campaign_id: String
    $campaign_version: String
    $message: String
    $user: String
  ) {
    getConversationLLama2Chat(
      agent: $agent
      campaign_id: $campaign_id
      campaign_version: $campaign_version
      message: $message
      user: $user
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const getConversationOpenAi = /* GraphQL */ `
  query GetConversationOpenAi(
    $message: String
    $campaign_id: String
    $campaign_version: String
    $user: String
    $agent: String
    $customer_id: String
    $is_concierge: Boolean
  ) {
    getConversationOpenAi(
      message: $message
      campaign_id: $campaign_id
      campaign_version: $campaign_version
      user: $user
      agent: $agent
      customer_id: $customer_id
      is_concierge: $is_concierge
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const getConversationMistral = /* GraphQL */ `
  query GetConversationMistral(
    $message: String
    $campaign_id: String
    $campaign_version: String
    $user: String
    $agent: String
  ) {
    getConversationMistral(
      message: $message
      campaign_id: $campaign_id
      campaign_version: $campaign_version
      user: $user
      agent: $agent
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const getConversationTascaAssistant = /* GraphQL */ `
  query GetConversationTascaAssistant(
    $message: String
    $campaign_id: String
    $campaign_version: String
    $user: String
    $customer_id: String
    $is_concierge: Boolean
    $thread: String
  ) {
    getConversationTascaAssistant(
      message: $message
      campaign_id: $campaign_id
      campaign_version: $campaign_version
      user: $user
      customer_id: $customer_id
      is_concierge: $is_concierge
      thread: $thread
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const getCampaigns = /* GraphQL */ `
  query GetCampaigns($account: String, $customer: String) {
    getCampaigns(account: $account, customer: $customer) {
      statusCode
      body
      __typename
    }
  }
`;
export const getConversations = /* GraphQL */ `
  query GetConversations($account: String, $campaign: String) {
    getConversations(account: $account, campaign: $campaign) {
      statusCode
      body
      __typename
    }
  }
`;
export const getCustomers = /* GraphQL */ `
  query GetCustomers($account: String) {
    getCustomers(account: $account) {
      statusCode
      body
      __typename
    }
  }
`;
export const getQuicksightDashboard = /* GraphQL */ `
  query GetQuicksightDashboard(
    $event_type: String
    $dashboard_id: String
    $user_arn: AWSJSON
    $initial_topic_id: String
  ) {
    getQuicksightDashboard(
      event_type: $event_type
      dashboard_id: $dashboard_id
      user_arn: $user_arn
      initial_topic_id: $initial_topic_id
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const putFiles = /* GraphQL */ `
  query PutFiles(
    $user_id: String
    $customer: String
    $s3_bucket: String
    $files: [FileInput!]!
  ) {
    putFiles(
      user_id: $user_id
      customer: $customer
      s3_bucket: $s3_bucket
      files: $files
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const deleteFiles = /* GraphQL */ `
  query DeleteFiles($user: String, $files: [FileDelete!]!) {
    deleteFiles(user: $user, files: $files) {
      statusCode
      body
      __typename
    }
  }
`;
export const getFiles = /* GraphQL */ `
  query GetFiles($applicant_id: String) {
    getFiles(applicant_id: $applicant_id) {
      statusCode
      body
      __typename
    }
  }
`;
export const putAgent = /* GraphQL */ `
  query PutAgent(
    $customer_id: String
    $id: String
    $applicant_id: String
    $prompt: String
    $provider: String
    $name: String
    $knowledge_base: [String]
  ) {
    putAgent(
      customer_id: $customer_id
      id: $id
      applicant_id: $applicant_id
      prompt: $prompt
      provider: $provider
      name: $name
      knowledge_base: $knowledge_base
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const getAgent = /* GraphQL */ `
  query GetAgent($customer_id: String, $domain: String, $provider: String) {
    getAgent(customer_id: $customer_id, domain: $domain, provider: $provider) {
      statusCode
      body
      __typename
    }
  }
`;
export const deleteAgent = /* GraphQL */ `
  query DeleteAgent($customer_id: String, $id: String) {
    deleteAgent(customer_id: $customer_id, id: $id) {
      statusCode
      body
      __typename
    }
  }
`;
export const putCustomer = /* GraphQL */ `
  query PutCustomer(
    $name: String
    $address: String
    $id: String
    $phone: String
    $email: String
    $linkedin: String
    $domains: [String]
    $status: String
    $email_domains: [String]
    $voice_numbers: [String]
    $sms_numbers: [String]
  ) {
    putCustomer(
      name: $name
      address: $address
      id: $id
      phone: $phone
      email: $email
      linkedin: $linkedin
      domains: $domains
      status: $status
      email_domains: $email_domains
      voice_numbers: $voice_numbers
      sms_numbers: $sms_numbers
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: String, $status: String) {
    getCustomer(id: $id, status: $status) {
      statusCode
      body
      __typename
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  query DeleteCustomer($id: String) {
    deleteCustomer(id: $id) {
      statusCode
      body
      __typename
    }
  }
`;
export const putContact = /* GraphQL */ `
  query PutContact(
    $customer: String
    $firstname: String
    $lastname: String
    $email: String
    $phone: String
    $id: String
    $insert_date: String
    $last_modified: String
    $active: Boolean
  ) {
    putContact(
      customer: $customer
      firstname: $firstname
      lastname: $lastname
      email: $email
      phone: $phone
      id: $id
      insert_date: $insert_date
      last_modified: $last_modified
      active: $active
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: String, $customer: String) {
    getContact(id: $id, customer: $customer) {
      statusCode
      body
      __typename
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  query DeleteContact($id: String, $customer: String) {
    deleteContact(id: $id, customer: $customer) {
      statusCode
      body
      __typename
    }
  }
`;
export const putFeedback = /* GraphQL */ `
  query PutFeedback(
    $user_id: String
    $vote: String
    $campaign: String
    $agent_id: String
    $comment: String
    $message: String
    $message_id: String
  ) {
    putFeedback(
      user_id: $user_id
      vote: $vote
      campaign: $campaign
      agent_id: $agent_id
      comment: $comment
      message: $message
      message_id: $message_id
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const getFeedback = /* GraphQL */ `
  query GetFeedback(
    $user_id: String
    $id: String
    $agent_id: String
    $campaign_id: String
    $message_id: String
    $vote: String
  ) {
    getFeedback(
      user_id: $user_id
      id: $id
      agent_id: $agent_id
      campaign_id: $campaign_id
      message_id: $message_id
      vote: $vote
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const deleteFeedback = /* GraphQL */ `
  query DeleteFeedback($user_id: String, $id: String) {
    deleteFeedback(user_id: $user_id, id: $id) {
      statusCode
      body
      __typename
    }
  }
`;
export const putPrompt = /* GraphQL */ `
  query PutPrompt(
    $user_id: String
    $prompt_name: String
    $prompt_description: String
    $human_instruction: String
    $commit_hash: String
    $features: String
    $guardrails: String
    $input_variables: [String]
    $id: String
  ) {
    putPrompt(
      user_id: $user_id
      prompt_name: $prompt_name
      prompt_description: $prompt_description
      human_instruction: $human_instruction
      commit_hash: $commit_hash
      features: $features
      guardrails: $guardrails
      input_variables: $input_variables
      id: $id
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const getPrompt = /* GraphQL */ `
  query GetPrompt($user_id: String, $id: String) {
    getPrompt(user_id: $user_id, id: $id) {
      statusCode
      body
      __typename
    }
  }
`;
export const deletePrompt = /* GraphQL */ `
  query DeletePrompt($user_id: String, $id: String) {
    deletePrompt(user_id: $user_id, id: $id) {
      statusCode
      body
      __typename
    }
  }
`;
export const getInternshipProgramConversation = /* GraphQL */ `
  query GetInternshipProgramConversation(
    $customer_id: String
    $applicant_id: String
    $message: String
  ) {
    getInternshipProgramConversation(
      customer_id: $customer_id
      applicant_id: $applicant_id
      message: $message
    ) {
      id
      statusCode
      status
      body
      __typename
    }
  }
`;
export const getLangchainProgramConversation = /* GraphQL */ `
  query GetLangchainProgramConversation(
    $message: String
    $bedrock_kb_id: String
    $model: String
    $email_address: String
  ) {
    getLangchainProgramConversation(
      message: $message
      bedrock_kb_id: $bedrock_kb_id
      model: $model
      email_address: $email_address
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const getPlaygroundConversation = /* GraphQL */ `
  query GetPlaygroundConversation(
    $customer_id: String
    $agent_id: String
    $message: String
    $thread: String
    $user_id: String
  ) {
    getPlaygroundConversation(
      customer_id: $customer_id
      agent_id: $agent_id
      message: $message
      thread: $thread
      user_id: $user_id
    ) {
      id
      statusCode
      status
      body
      __typename
    }
  }
`;
export const putInterview = /* GraphQL */ `
  query PutInterview(
    $customer: String
    $name: String
    $description: String
    $questions: [String]
    $id: String
  ) {
    putInterview(
      customer: $customer
      name: $name
      description: $description
      questions: $questions
      id: $id
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const getInterview = /* GraphQL */ `
  query GetInterview($interview: String) {
    getInterview(interview: $interview) {
      statusCode
      body
      __typename
    }
  }
`;
export const deleteInterview = /* GraphQL */ `
  query DeleteInterview($interview: String) {
    deleteInterview(interview: $interview) {
      statusCode
      body
      __typename
    }
  }
`;
export const putCampaignEmail = /* GraphQL */ `
  query PutCampaignEmail(
    $destination: [AWSJSON]
    $source: String
    $source_name: String
    $subject: String
    $text_body: String
    $id: String
    $type: String
    $name: String
    $status: String
    $totalRecipients: Int
    $html_body: String
    $metadata: AWSJSON
    $channels: AWSJSON
    $contact_file: AWSJSON
    $contact_headers_map: AWSJSON
  ) {
    putCampaignEmail(
      destination: $destination
      source: $source
      source_name: $source_name
      subject: $subject
      text_body: $text_body
      id: $id
      type: $type
      name: $name
      status: $status
      totalRecipients: $totalRecipients
      html_body: $html_body
      metadata: $metadata
      channels: $channels
      contact_file: $contact_file
      contact_headers_map: $contact_headers_map
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const putCampaignSms = /* GraphQL */ `
  query PutCampaignSms(
    $id: String
    $name: String
    $phoneNumber: String
    $source: String
    $type: String
    $status: String
    $totalRecipients: Int
    $metadata: AWSJSON
    $channels: AWSJSON
    $contact_file: AWSJSON
  ) {
    putCampaignSms(
      id: $id
      name: $name
      phoneNumber: $phoneNumber
      source: $source
      type: $type
      status: $status
      totalRecipients: $totalRecipients
      metadata: $metadata
      channels: $channels
      contact_file: $contact_file
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const getCampaign = /* GraphQL */ `
  query GetCampaign($customer: String, $status: String) {
    getCampaign(customer: $customer, status: $status) {
      statusCode
      body
      __typename
    }
  }
`;
