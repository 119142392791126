import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv, faTableList } from "@fortawesome/free-solid-svg-icons";

const buttonStyles = {
  color: "white",
  marginTop: "-20px",
  alignSelf: "center",
  marginBottom: "-2px",
  textTransform: "none",
  backgroundColor: "#015d86",
  "&:hover": { backgroundColor: "#014a6d" },
  "& .MuiButton-startIcon>*:nth-of-type(1)": {
    fontSize: "16px !important",
    marginRight: "10px",
  },
};

const ContactSelectionButtons = ({ onSelectMode, containerStyles }) => {
  return (
    <Box sx={containerStyles}>
      <Button
        variant="contained"
        onClick={() => onSelectMode("table")}
        sx={buttonStyles}
        startIcon={<FontAwesomeIcon icon={faTableList} />}
      >
        <Typography sx={{ fontSize: "18px" }}>
          Select Contacts from Table
        </Typography>
      </Button>
      <Button
        variant="contained"
        onClick={() => onSelectMode("import")}
        sx={buttonStyles}
        startIcon={<FontAwesomeIcon icon={faFileCsv} />}
      >
        <Typography sx={{ fontSize: "18px" }}>
          Import Contacts from CSV
        </Typography>
      </Button>
    </Box>
  );
};

export default ContactSelectionButtons;
