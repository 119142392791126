import React, { useEffect, useState, useCallback } from "react";
import { API } from "aws-amplify";
import { SyncLoader } from "react-spinners";
import {
  Box,
  useMediaQuery,
  useTheme,
  Button,
  Typography,
} from "@mui/material";
import PromptList from "./PromptList";
import PromptBuilder from "./PromptBuilder";
import useStore from "../../hooks/useStore";
import UseNotification from "../../utils/UseNotification";
import { deletePrompt, getPrompt, putPrompt } from "../../graphql/queries";
import { COLORS, DEFAULT_PROMPT_STATE } from "./utils/constants";
import NotificationDialog from "../../components/NotificationDialog/NotificationDialog";

const PromptDashboard = () => {
  const theme = useTheme();
  const { authStore } = useStore();
  const [isLoading, setIsLoading] = useState(true);
  const [loadError, setLoadError] = useState(null);
  const [validPrompts, setValidPrompts] = useState([]);
  const [promptsLoaded, setPromptsLoaded] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("lg"));

  console.log("authStore.userId: ", authStore.userId);

  const {
    openNotification,
    notificationContent,
    handleOpenNotification,
    handleCloseNotification,
  } = UseNotification();

  const loadPrompts = useCallback(async () => {
    setIsLoading(true);
    setLoadError(null);
    try {
      const params = {
        user_id: authStore.userId,
        id: "",
      };
      const response = await API.graphql({
        query: getPrompt,
        variables: params,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      console.log("response: ", response);
      const responseBody = JSON.parse(response.data.getPrompt.body);
      console.log("responseBody: ", responseBody);
      setValidPrompts(responseBody);
      setPromptsLoaded(true);
    } catch (error) {
      console.error("Error loading prompts: ", error);
      setLoadError("Failed to load prompts. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [authStore.userId]);

  useEffect(() => {
    if (!promptsLoaded) {
      loadPrompts();
    }
  }, [promptsLoaded, loadPrompts]);

  const handlePromptSelect = (prompt) => {
    setSelectedPrompt(prompt || DEFAULT_PROMPT_STATE);
  };

  const handleErrorNotification = (message) => {
    handleOpenNotification("Error", message, "error");
  };

  const handlePutPrompt = async (promptData) => {
    try {
      const response = await API.graphql({
        query: putPrompt,
        variables: promptData,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      console.log("response: ", response);
      if (!response.errors) {
        const responseBody = response.data.putPrompt.body;
        setPromptsLoaded(false);
        handleOpenNotification(
          "Success",
          `${responseBody} successfully.`,
          "success"
        );
      } else {
        handleErrorNotification(response.errors[0].message);
      }
    } catch (error) {
      handleErrorNotification(
        "An error occurred in the process, verify and try again."
      );
      console.error("Error during the process:", error);
    }
  };

  const handleDeletePrompt = async (data) => {
    try {
      const response = await API.graphql({
        query: deletePrompt,
        variables: data,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      console.log("response: ", response);
      const responseStatus = JSON.parse(response.data.deletePrompt.statusCode);
      if (responseStatus === 200) {
        setPromptsLoaded(false);
        setSelectedPrompt(null);
        handleOpenNotification(
          "Success",
          "Prompt successfully deleted.",
          "success"
        );
      } else {
        handleErrorNotification("An error occurred while deleting the prompt.");
      }
    } catch (error) {
      handleErrorNotification("An error occurred while deleting the prompt.");
      console.error("Error deleting prompts:", error);
    }
  };

  useEffect(() => {
    if (openNotification) {
      const timer = setTimeout(() => {
        handleCloseNotification();
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [openNotification, handleCloseNotification]);

  const renderPromptList = () => {
    if (isLoading) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <SyncLoader />
        </Box>
      );
    }

    if (loadError) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography variant="body1" color="error" gutterBottom>
            {loadError}
          </Typography>
          <Button
            variant="contained"
            onClick={loadPrompts}
            sx={{
              mt: "10px",
              backgroundColor: COLORS.PRIMARY,
              "&:hover": { backgroundColor: COLORS.PRIMARY_HOVER },
            }}
          >
            Retry
          </Button>
        </Box>
      );
    }

    return (
      <PromptList prompts={validPrompts} onPromptSelect={handlePromptSelect} />
    );
  };

  return (
    <Box
      display="flex"
      height="100%"
      flexDirection={isMobileOrTablet ? "column" : "row"}
    >
      <Box
        gap={2}
        width={isMobileOrTablet ? "100%" : "20%"}
        padding="10px"
        display="flex"
        borderRight={1}
        borderColor="divider"
        flexDirection="column"
        sx={{ backgroundColor: "#f9f9f9" }}
      >
        {renderPromptList()}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        sx={{
          position: "relative",
        }}
      >
        <PromptBuilder
          selectedPrompt={selectedPrompt}
          onSave={handlePutPrompt}
          onDelete={handleDeletePrompt}
        />
      </Box>
      <NotificationDialog
        open={openNotification}
        onClose={handleCloseNotification}
        title={notificationContent.title}
        message={notificationContent.message}
        type={notificationContent.type}
      />
    </Box>
  );
};

export default PromptDashboard;
