import React from "react";
import {
  Box,
  TextField,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useDrop } from "react-dnd";

const GeneralTab = ({
  senderName,
  onSenderNameChange,
  domain = '',
  onDomainChange,
  senderEmail,
  onSenderEmailChange,
  emailSubject,
  onEmailSubjectChange,
  isSending,
  sampleDomainOptions = [],
  onSenderNameFocus,
  onSenderNameBlur,
  onSubjectFocus,
  onSubjectBlur,
}) => {
  const [, dropSenderName] = useDrop({
    accept: "VARIABLE",
    drop: (item) =>
      onSenderNameChange({
        target: {
          value: senderName + `{{${item.value}}}`,
        },
      }),
  });

  const [, dropSubject] = useDrop({
    accept: "VARIABLE",
    drop: (item) =>
      onEmailSubjectChange({
        target: {
          value: emailSubject + `{{${item.value}}}`,
        },
      }),
  });

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ gap: 2, display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
          <Typography variant="subtitle2" fontWeight="bold">
            Sender Name
          </Typography>
          <div ref={dropSenderName}>
            <TextField
              id="sender-name"
              fullWidth
              value={senderName}
              onChange={onSenderNameChange}
              onFocus={onSenderNameFocus}
              onBlur={onSenderNameBlur}
              error={!senderName?.trim() && isSending}
              helperText={
                !senderName?.trim() && isSending ? "Sender name is required" : ""
              }
              sx={{
                mb: 2,
                "& .MuiInputBase-root": { height: "36px" },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "black" },
                  "&:hover fieldset": { borderColor: "black" },
                  "&.Mui-focused fieldset": { borderColor: "black" },
                },
              }}
              InputLabelProps={{ sx: { mt: "-5px", fontSize: "12px" } }}
            />
          </div>
        </Box>
        <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
          <Typography variant="subtitle2" fontWeight="bold">
            Domain
          </Typography>
          <FormControl fullWidth>
            <Select
              value={domain || ''}
              onChange={onDomainChange}
              error={!domain?.trim() && isSending}
              sx={{
                mb: 2,
                height: "36px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "black" },
                  "&:hover fieldset": { borderColor: "black" },
                  "&.Mui-focused fieldset": { borderColor: "black" },
                },
              }}
            >
              {Array.isArray(sampleDomainOptions) && sampleDomainOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Typography variant="subtitle2" fontWeight="bold">
        Sender Email Address
      </Typography>
      <TextField
        fullWidth
        value={senderEmail || ''}
        onChange={onSenderEmailChange}
        error={!senderEmail?.trim() && isSending}
        helperText={!senderEmail?.trim() && isSending ? "Email is required" : ""}
        sx={{
          mb: 2,
          "& .MuiInputBase-root": { height: "36px" },
          "& .MuiOutlinedInput-root": {
            "& fieldset": { borderColor: "black" },
            "&:hover fieldset": { borderColor: "black" },
            "&.Mui-focused fieldset": { borderColor: "black" },
          },
        }}
        InputLabelProps={{ sx: { mt: "-5px", fontSize: "12px" } }}
      />
      <Typography variant="subtitle2" fontWeight="bold">
        Email Subject
      </Typography>
      <div ref={dropSubject}>
        <TextField
          id="email-subject"
          fullWidth
          value={emailSubject || ''}
          onChange={onEmailSubjectChange}
          onFocus={onSubjectFocus}
          onBlur={onSubjectBlur}
          error={!emailSubject?.trim() && isSending}
          helperText={
            !emailSubject?.trim() && isSending ? "Subject is required" : ""
          }
          sx={{
            mb: 2,
            "& .MuiInputBase-root": { height: "36px" },
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "black" },
              "&:hover fieldset": { borderColor: "black" },
              "&.Mui-focused fieldset": { borderColor: "black" },
            },
          }}
          InputLabelProps={{ sx: { mt: "-5px", fontSize: "12px" } }}
        />
      </div>
    </Box>
  );
};

export default GeneralTab;
