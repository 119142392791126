import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import Box from "@mui/material/Box";
import { GridLoader } from "react-spinners";
import BasicSnackbar from "../../components/shared/material-components/BasicSnackbar";
import ConversationMessages from "../../components/ConversationMessages/ConversationMessages";
import ConversationFilter from "../../components/ConversationFilter/ConversationFilter";

const drawerWidth = 240;
const CampaignViewer = () => {
  const [open, setOpen] = useState(false);
  const [campaign, setCampaign] = useState(null);
  const [account, setAccount] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [isCustomerLoading, setIsCustomerLoading] = useState(true);

  useEffect(() => {
    const checkCustomer = async () => {
      setIsCustomerLoading(true);
      try {
        const userInfo = await Auth.currentAuthenticatedUser();
        const customerFromClaim = userInfo.attributes["custom:account"];
        console.log("Customer fetched:", customerFromClaim);
        setCustomer(customerFromClaim);
        setAccount(customerFromClaim);
      } catch (e) {
        console.error("Error getting customer:", e);
        setOpen(true);
      } finally {
        setIsCustomerLoading(false);
      }
    };

    checkCustomer();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const onSelectCampaign = (e, selectedCampaign) => {
    e.preventDefault();
    if (!selectedCampaign) return;
    console.log("Selected campaign:", selectedCampaign);
    setCampaign(selectedCampaign);
  };

  const onSelectAccount = (e, selectedAccount) => {
    e.preventDefault();
    if (!selectedAccount) return;
    console.log("Selected account:", selectedAccount);
    setAccount(selectedAccount);
    setCustomer(selectedAccount);
    setCampaign(null);
  };

  const onSelectCustomer = (e, selectedCustomer) => {
    e.preventDefault();
    if (!selectedCustomer) return;
    console.log("Selected customer:", selectedCustomer);
    setCustomer(selectedCustomer);
    setCampaign(null);
  };

  if (isCustomerLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          marginTop: "20%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <GridLoader />
      </Box>
    );
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        marginTop: "0",
      }}
    >
      <ConversationFilter
        onError={() => setOpen(true)}
        account={account}
        customer={customer}
        campaign={campaign}
        onSelectAccount={onSelectAccount}
        onSelectCustomer={onSelectCustomer}
        onSelect={onSelectCampaign}
      />

      {customer && (
        <ConversationMessages
          onError={() => setOpen(true)}
          campaign={campaign}
          account={account}
        />
      )}

      <BasicSnackbar
        open={open}
        severity="error"
        message="Data couldn't be fetched"
        onClose={handleClose}
      />
    </Box>
  );
};

export default CampaignViewer;
