import React, {useState, useEffect} from "react";
import {IconButton, List, ListItem, ListItemText, TextField,} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const DomainListManager = ({domains = [], onDomainListChange}) => {
    const [domain, setDomain] = useState("");
    const [domainList, setDomainList] = useState(domains);

    // Sync with external domains prop
    useEffect(() => {
        setDomainList(domains);
    }, [domains]);

    const handleAddDomain = () => {
        if (domain.trim() && isValidDomain(domain)) {
            const updatedList = [...domainList, domain];
            setDomainList(updatedList);
            setDomain(""); // Clear the input field
            onDomainListChange(updatedList); // Remove 'email_domains' parameter
        } else {
            alert("Please enter a valid domain.");
        }
    };

    const handleRemoveDomain = (indexToRemove) => {
        const updatedList = domainList.filter((_, index) => index !== indexToRemove);
        setDomainList(updatedList);
        onDomainListChange(updatedList); // Remove 'email_domains' parameter
    };

    const isValidDomain = (string) => {
        // Basic regex to validate email domains
        const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return domainRegex.test(string);
    };

    return (
        <div>
            <TextField
                variant="outlined"
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
                sx={{
                    "& .MuiInputBase-root": {height: "36px"},
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": {borderColor: "black"},
                        "&:hover fieldset": {borderColor: "black"},
                        "&.Mui-focused fieldset": {borderColor: "black"},
                    },
                }}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        e.preventDefault();
                        handleAddDomain();
                    }
                }}
            />
            <IconButton
                edge="end"
                aria-label="add"
                onClick={handleAddDomain} // Fixed onClick handler
            >
                <AddCircleOutlineIcon/>
            </IconButton>
            <List>
                {domainList.map((domain, index) => (
                    <ListItem
                        key={index}
                        secondaryAction={
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => handleRemoveDomain(index)}
                            >
                                <DeleteIcon/>
                            </IconButton>
                        }
                    >
                        <ListItemText primary={domain}/>
                    </ListItem>
                ))}
            </List>
        </div>
    );
};

export default DomainListManager;
