import PhoneListManager from "../../../components/PhoneListManager/PhoneListManager";

export const COLUMN_CUSTOMERS = [
    {id: "name", label: "Customer"},
    {id: "email", label: "Email"},
    {id: "phone", label: "Phone"},
    {id: "domains", label: "Domains"},
];

export const CUSTOMER_DATA_VALUES = {
    id: "",
    name: "",
    phone: "",
    email: "",
    address: "",
    linkedin: "",
    domains: [],
    email_domains: [],
    sms_numbers: [],
    voice_numbers: [],
    status: "Created",
};

export const CUSTOMER_FIELDS = [
    {
        name: "name",
        label: "Name",
        type: "text",
        validate: (value) => (value.trim() ? "" : "Name is required"),
    },
    {
        name: "phone",
        label: "Phone Number",
        type: "text",
        validate: (value) => (/^\+?[1-9]\d{1,14}$/.test(value) ? "" : "Invalid phone number"),
    },
    {
        name: "email",
        label: "Email",
        type: "email",
        validate: (value) =>
            /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? "" : "Invalid email address",
    },
    {
        name: "address",
        label: "Address",
        type: "text",
        validate: (value) => (value.trim() ? "" : "Address is required"),
    },
    {
        name: "linkedin",
        label: "LinkedIn",
        type: "text"
    },
    {
        name: "domains",
        label: "Site URL",
        type: "UrlListManager"
    },
    {
        name: "email_domains",
        label: "Email domains",
        type: "DomainListManager"
    },
    {
        name: "sms_numbers",
        label: "SMS numbers",
        type: "PhoneListManager"
    },
    {
        name: "voice_numbers",
        label: "VOICE numbers",
        type: "PhoneListManager"
    }
];
