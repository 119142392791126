import React from "react";
import { 
  Box, 
  Button, 
  Typography, 
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormHelperText
} from "@mui/material";
import { slideOverStyles } from "./styles/CampaignSlideOver.styles";

const SMSCampaignForm = ({
  campaignName,
  setCampaignName,
  phoneNumber,
  setPhoneNumber,
  isSending,
  onSubmit,
  phoneNumbers = [],
}) => {
  return (
    <Box sx={slideOverStyles.formContainer}>
      <Typography variant="subtitle2" sx={slideOverStyles.formTitle}>
        Campaign Name
      </Typography>
      <TextField
        fullWidth
        value={campaignName}
        onChange={(e) => setCampaignName(e.target.value)}
        placeholder="Enter campaign name"
        error={!campaignName.trim() && isSending}
        helperText={!campaignName.trim() && isSending ? "Name is required" : ""}
        sx={slideOverStyles.textField}
      />

      <Typography variant="subtitle2" sx={slideOverStyles.formTitle}>
        Phone Number
      </Typography>
      <FormControl 
        fullWidth 
        error={!phoneNumber.trim() && isSending}
      >
        <Select
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          displayEmpty
          sx={slideOverStyles.textField}
        >
          <MenuItem value="" disabled>
            Select a phone number
          </MenuItem>
          {phoneNumbers.map((number) => (
            <MenuItem key={number} value={number}>
              {number}
            </MenuItem>
          ))}
        </Select>
        {!phoneNumber.trim() && isSending && (
          <FormHelperText>Phone number is required</FormHelperText>
        )}
      </FormControl>

      <Box sx={slideOverStyles.infoBox}>
        <Typography variant="subtitle2" sx={slideOverStyles.infoTitle}>
          About Inbound SMS Campaigns
        </Typography>
        <Typography variant="body2" sx={slideOverStyles.infoText}>
          This is an inbound SMS campaign, which means:
          <ul style={{ marginTop: "4px", marginBottom: "4px" }}>
            <li>
              Customers can initiate conversations by texting to this number
            </li>
            <li>
              The AI agent will automatically respond to incoming messages
            </li>
            <li>
              All interactions are handled through the assigned phone number
            </li>
            <li>
              No contact list needed - anyone can text this number to interact
            </li>
          </ul>
          The campaign will be active once created, and the AI agent will handle
          all incoming messages automatically.
        </Typography>
      </Box>

      <Button
        variant="contained"
        onClick={onSubmit}
        disabled={isSending}
        sx={slideOverStyles.submitButton}
      >
        {isSending ? "Creating..." : "Create Campaign"}
      </Button>
    </Box>
  );
};

export default SMSCampaignForm;
