import React, { useState, useEffect } from "react";
import { IconButton, List, ListItem, ListItemText, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const UrlListManager = ({ urls = [], onChange }) => {
    const [url, setUrl] = useState("");
    const [urlList, setUrlList] = useState(urls);

    useEffect(() => {
        setUrlList(urls);
    }, [urls]);

    const handleAddUrl = () => {
        if (url.trim() && isValidUrl(url)) {
            const updatedList = [...urlList, url];
            setUrlList(updatedList);
            setUrl(""); // Clear the input field
            onChange(updatedList);
        } else {
            alert("Please enter a valid URL.");
        }
    };

    const handleRemoveUrl = (indexToRemove) => {
        const updatedList = urlList.filter((_, index) => index !== indexToRemove);
        setUrlList(updatedList);
        onChange(updatedList);
    };

    const isValidUrl = (string) => {
        try {
            new URL(string);
            return true;
        } catch {
            return false;
        }
    };

    return (
        <div>
            <TextField
                fullWidth
                variant="outlined"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                sx={{
                    "& .MuiInputBase-root": { height: "36px" },
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": { borderColor: "black" },
                        "&:hover fieldset": { borderColor: "black" },
                        "&.Mui-focused fieldset": { borderColor: "black" },
                    },
                }}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        e.preventDefault();
                        handleAddUrl();
                    }
                }}
            />
            <IconButton
                edge="end"
                aria-label="add"
                onClick={handleAddUrl}
            >
                <AddCircleOutlineIcon />
            </IconButton>
            <List>
                {urlList.map((url, index) => (
                    <ListItem
                        key={index}
                        secondaryAction={
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => handleRemoveUrl(index)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        }
                    >
                        <ListItemText primary={url} />
                    </ListItem>
                ))}
            </List>
        </div>
    );
};

export default UrlListManager;
